import React, {useEffect, useState} from "react";
import Table from "../../ui/Table";
import {get} from "lodash";
import {useParams} from "react-router-dom";
import {GET, PGET} from "../../../api/adminApi";
import {Button, Checkbox, Tag} from "antd";
import {FaMoneyBill} from "react-icons/fa6";
import MonthlyPayment from "./MonthlyPayment";
import FreePayment from "./FreePayment";
import UniquePayment from "./UniquePayment";

const AgreementPayment = () => {
    const {id} = useParams()
    const [productDetails, setProductDetails] = useState()
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(15);
    const [selectedIds, setSelectedIds] = useState([]);
    const [uniquePayment, setUniquePayment] = useState(false)
    const [currentId, setCurrentId] = useState(null);
    const [serverData, setServerData] = useState()

    async function getServerData() {
        const response = await GET("/services/admin/api/agreement-current-date");
        if (response) {
            console.log(response)
            setServerData(response)
        }
    }
    async function getProductDetails() {
        const response = await PGET(`/services/admin/api/agreement-installments`, true, {
            agreementId: id
        })
        if (response) {
            setProductDetails(response)
        }
    }

    useEffect(() => {
        getProductDetails()
        getServerData()
    }, []);

    const handleCheckboxClick = (id, index) => {
        if (selectedIds.includes(id)) {
            const currentIdIndex = productDetails.data.findIndex(item => item.id === id);
            setSelectedIds(selectedIds.filter(selectedId => {
                const selectedIndex = productDetails.data.findIndex(item => item.id === selectedId);
                return selectedIndex < currentIdIndex;
            }));
        } else {
            const newSelectedIds = [];
            for (let i = 0; i <= index; i++) {
                if (productDetails.data[i].paidStatus !== 2) {
                    newSelectedIds.push(productDetails.data[i].id);
                }
            }
            setSelectedIds(newSelectedIds);
        }
    };

    const handleUniquePayment = (id) => {
        setCurrentId(id)
        setUniquePayment(true)
    }
    const columns = [
        {
            title: "",
            key: "checkbox",
            dataIndex: "id",
            render: (id, item, index) => (
                <Checkbox
                    checked={selectedIds.includes(id)}
                    disabled={item.paidStatus === 2}
                    onChange={() => handleCheckboxClick(id, index)}
                />
            ),
        },
        {
            title: "ID договора",
            key: "agreementId",
            dataIndex: "agreementId",
            render: (item) => (
                <div className="text-left">
                    {item}
                </div>
            ),
        },
        {
            title: "Статус договора",
            key: "paidStatus",
            dataIndex: "paidStatus",
            render: (item) => (
                <div className="text-left">
                    {item === 1 ? <Tag color="red">Не оплачено</Tag>
                        : item === 2 ? <Tag color="green">Оплачено</Tag>
                            : '-'
                    }
                </div>
            ),
        },
        {
            title: "Дата оплаты",
            key: "paymentDate",
            dataIndex: "paymentDate",
            render: (item) => (
                <div className="text-left">
                    {item || '-'}
                </div>
            ),
        },
        {
            title: "Комментария",
            key: "comment",
            dataIndex: "comment",
            render: (item) => (
                <div className="text-left">
                    {item || '-'}
                </div>
            ),
        },
        {
            title: "Последняя дата оплаты",
            key: "lastPaymentTime",
            dataIndex: "lastPaymentTime",
            render: (item) => (
                <div className="text-left">
                    {item || '-'}
                </div>
            ),
        },
        {
            title: "Оплаченная сумма",
            key: "paidAmount",
            dataIndex: "paidAmount",
            render: (item) => (
                <div className="text-left">
                    {item || '-'}
                </div>
            ),
        },
        {
            title: "Сумма оплаты",
            key: "paymentAmount",
            dataIndex: "paymentAmount",
            render: (item) => (
                <div className="text-left">
                    {item || '-'}
                </div>
            ),
        },
        {
            title: "Остаток сумма",
            key: "remainedPaymentAmount",
            dataIndex: "remainedPaymentAmount",
            render: (item) => (
                <div className="text-left">
                    {item || '-'}
                </div>
            ),
        },
        {
            title: "Действия",
            key: "id",
            dataIndex: "id",
            render: (id, item) => (
                <Button
                    disabled={item.paidStatus === 2}
                    icon={<FaMoneyBill/>}
                    onClick={() => handleUniquePayment(id)}
                    className="text-left"
                />
            ),
        },
    ]
    return (
        <div className="p-5">
            <UniquePayment
                setOpen={setUniquePayment}
                open={uniquePayment}
                getItems={getProductDetails}
                currentId={currentId}
            />
            <div className="flex justify-between items-center">
                <h3 className="my-5">Оплаты договора</h3>
                <div className="flex">
                    <FreePayment
                        getItems={getProductDetails}
                        serverData={serverData}
                    />
                    <MonthlyPayment
                        selectedIds={selectedIds}
                        setSelectedIds={setSelectedIds}
                        getItems={getProductDetails}
                    />
                </div>
            </div>
            <Table
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                data={get(productDetails, "data", [])}
                totalElements={get(productDetails, "data", [])}
                // onRow={rowClick}
                // isLoading={isLoading || isFetching}
                columns={columns}
            />
        </div>
    )
}

export default AgreementPayment