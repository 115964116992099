import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {DebounceInput} from "react-debounce-input";
import ReactPaginate from "react-paginate";
import {PGET, DELETE} from "../../../api/adminApi";
import ConditionDeleteModal from "./ConditionDeleteModal";
import {CustomSvg} from "../../../utils/CustomSvg";

function Condition() {
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(+localStorage.getItem("currentPageCondition") || 0); //for numeration and saving currentPage after refresh
    const [deleteModal, setDeleteModal] = useState({
        show: false, investment: null,
    });

    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPageCondition", 0);
    }

    const paginate = (data) => {
        setCurrentPage(data.selected);
        localStorage.setItem("currentPageCondition", data.selected); //for saving currentPage after refresh
    };

    async function getItems() {
        const data = await PGET("/services/admin/api/condition-pageList", true, {
            page: currentPage, size: 10, search: value,
        });
        setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
        setItems(data.data);
    }

    async function deleteItem(id) {
        await DELETE("/services/admin/api/condition/" + id);
        getItems();
    }

    useEffect(() => {
        getItems();
    }, [currentPage, value]); // eslint-disable-line react-hooks/exhaustive-deps

    return (<div
        className="br-5 card"
        style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}
    >
        <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Ставка</span>
            </h3>
            <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click to add a user'
            >
                <Link
                    to="/admin/condition/create"
                    className='btn btn-sm btn-light-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_invite_friends'
                >
                    <CustomSvg path='/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                    Добавить новый
                </Link>
            </div>
        </div>
        <div className="px-3 pb-4 pt-2">
            <div className="mb-4 d-flex justify-content-between">
                <div className="d-flex gap-4">
                    <div className="position-relative ">
                        <DebounceInput
                            minLength={3}
                            debounceTimeout={400}
                            value={value}
                            onChange={handleSearchInput}
                            type="text"
                            className="input-search search-icon br-5 "
                            size={24}
                            placeholder="Поиск"
                        />
                    </div>
                </div>
            </div>
            <div style={{overflow: "auto", maxHeight: '75vh'}} className='position-relative'>
                <table className='table p-0 bg-white table-rounded border gy-4 gs-7'>
                    <thead className='position-sticky top-0 rounded'>
                    <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                        <th style={{
                            width: "30px",
                            fontWeight: "700",
                            borderTopLeftRadius: '8px'
                        }} className="text-center text-white backSvet">№
                        </th>
                        <th style={{fontWeight: "700"}}
                            className="text-start text-white backSvet">Название
                        </th>
                        <th style={{ fontWeight: "700"}}
                            className="text-start text-white backSvet">Информация
                        </th>
                        <th style={{fontWeight: "700"}}
                            className="text-start text-white backSvet">Процент
                        </th>
                        <th style={{fontWeight: "700", borderTopRightRadius: '8px'}}
                            className="text-start text-white backSvet"></th>
                    </tr>
                    </thead>

                    <tbody style={{overflowX: "auto"}}>
                    {items.map((item, index) => {
                        return (<tr className='borderBottom' key={item.id}>
                            <td className="text-center">
                                {currentPage === 0 ? index + 1 : currentPage * 10 + index + 1}
                            </td>
                            <td className="text-start">{item.name}</td>

                            <td className="text-start">{item.description}</td>
                            <td className="text-start">
                                {item.percentage}
                            </td>
                            <td>
                                <div className='d-flex justify-content-end flex-shrink-0'>
                                    <Link
                                        to={"/admin/condition/update/" + item.id}
                                        href='#'
                                        className='btn btn-icon btn-active-color-primary btn-sm me-1 shadow-sm'
                                    >
                                        <CustomSvg path='/icons/duotune/art/art005.svg' className='svg-icon-3'/>
                                    </Link>
                                    <button
                                        onClick={() => deleteItem(item.id)}
                                        className='btn btn-icon shadow-sm btn-active-color-primary btn-sm'
                                    >
                                        <CustomSvg
                                            path='/icons/duotune/general/gen027.svg'
                                            className='svg-icon-3'
                                        />
                                    </button>
                                </div>

                            </td>
                        </tr>);
                    })}
                    </tbody>
                </table>
            </div>
            {pageCount > 1 && (<ReactPaginate
                forcePage={currentPage}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1}
                onPageChange={paginate}
                containerClassName={"pagination"}
                activeClassName={"bg-dark text-white"}
                pageClassName={"page-item box-shadow br-5"}
            />)}
        </div>

        {deleteModal.investment && (<ConditionDeleteModal
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            getItems={getItems}
        />)}
    </div>);
}

export default Condition;
