import React from "react";
import Statistics from "./Statistics";
import {Card, Col, Row} from "antd";
import {BarChartOne} from "./BarChartOne";
import {TableChart} from "./TableChart";
import {TableChartTwo} from "./TableChartTwo";

function Dashboard() {
    return (
        <>
            <div className="br-5 overflow-y-auto"
                 style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh', padding: "0 20px"}}>
                <Statistics/>
                <Row gutter={[16, 16]} className="mt-4">
                    <Col span={12}>

                    </Col>
                </Row>
                <Card title="Статистика по приходу и прыбили">
                    <BarChartOne className="foo" style={{marginBottom: '20px'}}/>
                </Card>
                <Card title="История всех операции" className="mt-4">
                    <TableChartTwo/>
                </Card>
                <Card title="Статистика по приходу и прибыли" className="mt-4 mb-4">
                    <TableChart/>
                </Card>
            </div>
        </>
    );
}

export default Dashboard;
