import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Form, Input, InputNumber, Modal, Radio, Select} from 'antd';
import {CiCirclePlus} from "react-icons/ci";
import {GET, POST} from "../../../api/adminApi";
import {useParams} from "react-router-dom";
import {moneyFormatter} from "../../../utils/utilities";
import {get, map} from "lodash";

const AddProduct = ({open, setOpen, getItems, getProduct}) => {
    const {id} = useParams()
    const [form] = Form.useForm()
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [productList, setProductList] = useState()
    const showModal = () => {
        setOpen(true);
    };
    const handleSubmit = async (data) => {

        const obj = {
            ...data,
            agreementId: Number(id)
        }

        setConfirmLoading(true);
        const res = await POST("/services/admin/api/agreement-product", obj);
        if (res) {
            getItems()
            getProduct()
            form.resetFields()
            setOpen(false);
            setConfirmLoading(false);
        }
    };
    const handleCancel = () => {
        setOpen(false);
    };

    async function getHelpers() {
        const response = await GET("/services/admin/api/product-helper")
        if (response) {
            setProductList(response)
        }
    }

    useEffect(() => {
        getHelpers()
    }, []);

    return (
        <>
            <Button className="" type="primary" onClick={showModal}
                    icon={<CiCirclePlus size="20px"/>}>Добавить продукт</Button>
            <Modal
                title="Добавить товар"
                open={open}
                onOk={() => form.submit()}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    <Form.Item label="Товары" name="productId" rules={[{required: true}]}>
                        <Select
                            showSearch
                            optionFilterProp="label"
                            options={map(productList, (item) => {
                                return {
                                    value: get(item, "id"),
                                    label: get(item, "name"),
                                };
                            })}/>
                    </Form.Item>
                    <Form.Item
                            label="Количество"
                            name="amount"
                            rules={[
                                {required: true},
                                {
                                    validator: async (_, value) => {
                                        if (value <= 100000000000) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(`Введите ниже ${moneyFormatter(100000000000)}`));
                                    }
                                }
                            ]}
                        >
                            <InputNumber
                                style={{width: '100%'}}
                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />
                        </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default AddProduct;