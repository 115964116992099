import React, {useState, useEffect} from "react";
import {useParams, useHistory, Link} from "react-router-dom";
import {XCircle, Save} from "react-feather";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import {POST, GET, PUT} from "../../../api/adminApi";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import {isValidPhoneNumber} from "react-phone-number-input";

function UserAddUpdate() {
    const {id: operatorId} = useParams();
    const history = useHistory();
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
        setValue,
        getValues,
    } = useForm();
    const [data, setData] = useState({
        approved: 0,
    });
    const [mobilePhoneErr, setPhoneErr] = useState(null);

    function onSubmit(values) {
        const obj = {
            ...data,
            fullName: values.fullName,
            login: values.login,
            password: values.password,
            mobilePhone: values.mobilePhone.slice(1),
        };
        checkPhoneNumber();

        async function create() {
            if (operatorId) {
                const response = await PUT("/services/admin/api/operators", obj);
                if (response?.success) {
                    history.push("/admin/user");
                }
            } else {
                const response = await POST("/services/admin/api/operators", obj);
                if (response?.success) {
                    history.push("/admin/user");
                    toast.success("Успешно добавлен");
                }
            }
        }

        if (!mobilePhoneErr) {
            create();
        }
    }

    async function checkPhoneNumber() {
        setPhoneErr(null);
        const res = await POST(`/services/admin/api/operators/phone-check`, {
            mobilePhone: getValues.mobilePhone,
        });

        if (res?.success) {
            setPhoneErr(null);
        }
        if (res?.response?.status === 400) {
            setPhoneErr(res.response?.data?.message);
        } else {
            setPhoneErr(" ");
        }
    }

    async function getData() {
        const response = await GET("/services/admin/api/operators/" + operatorId);
        if (response) {
            const {
                id,
                fullName,
                mobilePhone,
                login,
                password
            } = response;
            setValue("fullName", fullName);
            setValue("login", login);
            setValue("password", password);
            setValue("mobilePhone", "+" + mobilePhone);
            setData((prev) => ({
                ...prev,
                id,
            }));

        }
    }

    useEffect(() => {
        if (operatorId) {
            getData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className="mb-1 mx-3">
            <h3 className="mb-5 mt-5">
                {operatorId ? "Изменить" : "Добавить мастер"}
            </h3>

            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="admin-card p-4 overflow-y-auto pt-12 d-flex flex-column justify-content-between">
                    <div className="mb-1 d-flex">
                        <div className="">
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    ФИО
                                </label>
                                <div>
                                    <input
                                        {...register("fullName")}
                                        type="text"
                                        className="input-control"
                                        style={{width: "300px"}}
                                    />
                                </div>
                            </div>

                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Номер телефона
                                </label>
                                <div>
                                    <PhoneInput
                                        name="mobilePhone"
                                        control={control}
                                        rules={{
                                            validate: isValidPhoneNumber,
                                        }}
                                        international
                                        withCountryCallingCode
                                        country="UZ"
                                        style={{width: "300px"}}
                                        className="input-control"
                                    />
                                    {errors.mobilePhone && (
                                        <div className="text-danger mt-1 fz12">
                                            "Заполните полностью и правильно"
                                        </div>
                                    )}

                                    {mobilePhoneErr && (
                                        <p className="text-danger m-0">{mobilePhoneErr}</p>
                                    )}

                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Логин
                                </label>
                                <div>
                                    <input
                                        {...register("login")}
                                        type="text"
                                        className="input-control"
                                        style={{width: "300px"}}
                                    />
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Пароль
                                </label>
                                <div>
                                    <input
                                        {...register("password")}
                                        type="text"
                                        className="input-control"
                                        style={{width: "300px"}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end">
                        <Link to="/admin/user" className="mr-3">
                            <button className="btn text-white btn-bg-primary d-flex align-items-center">
                                <XCircle size={14} className="mr-2"/>
                                Отмена
                            </button>
                        </Link>
                        <button
                            className="btn text-white btn-bg-success d-flex align-items-center"
                            type="submit"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
        ;
}

export default UserAddUpdate;
