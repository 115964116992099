import React, {useState, useEffect} from "react";
import {useParams, useHistory, Link} from "react-router-dom";
import {XCircle, Save} from "react-feather";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import {POST, GET, PUT, FILE} from "../../../api/adminApi";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import {isValidPhoneNumber} from "react-phone-number-input";

function ClientAddUpdate() {
    const {id: operatorId} = useParams();
    const history = useHistory();
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
        setValue,
        getValues,
    } = useForm();
    const [data, setData] = useState({});
    const [mobilePhoneErr, setPhoneErr] = useState(null);

    function onSubmit(values) {
        const obj = {
            ...data,
            address: values.address,
            birthDate: values.birthDate,
            description: values.description,
            firstName: values.firstName,
            lastName: values.lastName,
            passportNumber: values.passportNumber,
            phone: values.phone,
            phoneAdditional: values.phoneAdditional,
            photoFilePath: values.photoFilePath,
            // mobilePhone: values.mobilePhone.slice(1),
        };

        async function create() {
            if (operatorId) {
                const response = await PUT("/services/admin/api/client", obj);
                if (response?.success) {
                    history.push("/admin/user");
                }
            } else {
                const response = await POST("/services/admin/api/client", obj);
                if (response?.success) {
                    history.push("/admin/user");
                    toast.success("Успешно добавлен");
                }
            }
        }
        create()
    }

    async function getData() {
        const response = await GET("/services/admin/api/client/" + operatorId);
        if (response) {
            const {
                id,
                address,
                birthDate,
                description,
                firstName,
                lastName,
                passportNumber,
                phone,
                phoneAdditional,
                photoFilePath,
            } = response;
            setValue("address", address);
            setValue("birthDate", birthDate);
            setValue("description", description);
            setValue("firstName", firstName);
            setValue("lastName", lastName);
            setValue("passportNumber", passportNumber);
            setValue("phone", phone);
            setValue("phoneAdditional",phoneAdditional);
            setValue("photoFilePath", "+" + photoFilePath);
            setData((prev) => ({
                ...prev,
                id,
            }));
        }
    }
    async function getImageUrl(e) {
        if (e.target.files[0]) {
            const image = e.target.files[0];
            const formData = new FormData();
            formData.append('image', image);
            const response = await FILE("/services/admin/api/client-image-upload", formData);
            setData({ ...data, imageUrl: response.url })
            setValue('imageUrl', response.url)
        }
    }

    useEffect(() => {
        if (operatorId) {
            getData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className="mb-1 mx-3">
            <h3 className="mb-5 mt-5">
                {operatorId ? "Изменить" : "Добавить клиента"}
            </h3>

            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="admin-card p-4 overflow-y-auto pt-12 d-flex flex-column justify-content-between">

                    <div className="mb-1 d-flex">
                        <div className="">
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Имя
                                </label>
                                <div>
                                    <input
                                        {...register("firstName")}
                                        type="text"
                                        className="input-control"
                                        style={{width: "300px"}}
                                    />
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Фамилия
                                </label>
                                <div>
                                    <input
                                        {...register("lastName")}
                                        type="text"
                                        className="input-control"
                                        style={{width: "300px"}}
                                    />
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Номер пасспорта
                                </label>
                                <div>
                                    <input
                                        {...register("passportNumber")}
                                        type="text"
                                        className="input-control"
                                        style={{width: "300px"}}
                                    />
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Адрес
                                </label>
                                <div>
                                    <input
                                        {...register("address")}
                                        type="text"
                                        className="input-control"
                                        style={{width: "300px"}}
                                    />
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Описание
                                </label>
                                <div>
                                    <input
                                        {...register("description")}
                                        type="text"
                                        className="input-control"
                                        style={{width: "300px"}}
                                    />
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Номер телефона
                                </label>
                                <div>
                                    <PhoneInput
                                        name="phone"
                                        control={control}
                                        rules={{
                                            validate: isValidPhoneNumber,
                                        }}
                                        international
                                        withCountryCallingCode
                                        country="UZ"
                                        style={{width: "300px"}}
                                        className="input-control"
                                    />
                                    {errors.phone && (
                                        <div className="text-danger mt-1 fz12">
                                            "Заполните полностью и правильно"
                                        </div>
                                    )}

                                    {mobilePhoneErr && (
                                        <p className="text-danger m-0">{mobilePhoneErr}</p>
                                    )}

                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Доп телефонный номер
                                </label>
                                <div>
                                    <PhoneInput
                                        name="phoneAdditional"
                                        control={control}
                                        rules={{
                                            validate: isValidPhoneNumber,
                                        }}
                                        international
                                        withCountryCallingCode
                                        country="UZ"
                                        style={{width: "300px"}}
                                        className="input-control"
                                    />
                                    {errors.phoneAdditional && (
                                        <div className="text-danger mt-1 fz12">
                                            "Заполните полностью и правильно"
                                        </div>
                                    )}
                                    {mobilePhoneErr && (
                                        <p className="text-danger m-0">{mobilePhoneErr}</p>
                                    )}
                                </div>
                            </div>
                            <div className="d-flex w-100">
                                    <div className='admin-label'><label className="mb-0">Фото</label></div>
                                    <div className='col-md-8 col-sm-8 col-10'>
                                        <div className="position-relative">
                                            <div className="image-block"
                                                 style={{
                                                     width: '200px',
                                                     minWidth: '100px',
                                                     height: '200px',
                                                     border: '1px dashed #D4D3D6'
                                                 }}>
                                                Перетащите сюда файлы для загрузки
                                            </div>
                                            <input onChange={(e) => getImageUrl(e)} type="file" placeholder="Фамилия"
                                                   style={{
                                                       width: '200px',
                                                       minWidth: '100px',
                                                       height: '200px',
                                                       opacity: '0',
                                                       top: 0,
                                                       zIndex: 3
                                                   }}
                                                   className="position-absolute cursor border border-radius-3 box outline-none color-grey"/>
                                            {/* {errors.imageUrl?.type === 'required' && <div className='text-danger mt-1 fz12'>Обязательное поле</div>} */}
                                            {data.imageUrl &&
                                                <div className="position-absolute cursor  border-radius-3" style={{
                                                    width: '200px',
                                                    minWidth: '100px',
                                                    height: '200px',
                                                    top: 0,
                                                    zIndex: 2
                                                }}>
                                                    <img className="w-100 h-100 background-white border box-shadow"
                                                         src={'https://bo.saleinvest.uz' + (data.imageUrl)} alt=""/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end">
                        <Link to="/admin/user" className="mr-3">
                            <button className="btn text-white btn-bg-primary d-flex align-items-center">
                                <XCircle size={14} className="mr-2"/>
                                Отмена
                            </button>
                        </Link>
                        <button
                            className="btn text-white btn-bg-success d-flex align-items-center"
                            type="submit"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
        ;
}

export default ClientAddUpdate;
