import React, {useState, useEffect} from "react";
import {useParams, useHistory} from "react-router-dom";
import {toast} from "react-toastify";
import {POST, GET, PUT} from "../../../api/adminApi";
import {Button, Form, Input, Select, DatePicker, Radio} from "antd";
import {get, map} from "lodash";
import moment from "moment";
import dayjs from "dayjs";

function AgreementAddUpdate() {
    const {id: investmentId} = useParams();
    const history = useHistory();
    const [investmentsList, setInvestmentsList] = useState()
    const [clientsList, setClientsList] = useState()
    const [installsList, setInstallsList] = useState()
    const [conditionsList, setConditionsList] = useState()
    const [initialData, setInitialData] = useState()
    const [form] = Form.useForm()
    const [startWithCurrentDate, setStartWithCurrentDate] = useState(false);
    const [serverData, setServerData] = useState()

    async function getServerData() {
        const response = await GET("/services/admin/api/agreement-current-date");
        if (response) {
            setServerData(response)
        }
    }

    const handleRadioChange = e => {
        setStartWithCurrentDate(e.target.value);
    };
    async function getData() {
        const response = await GET("/services/admin/api/agreement/" + investmentId);
        if (response) {
            setInitialData(response)
        }
    }

    async function getHelpers() {
        const investments = await GET("/services/admin/api/investment-helper")
        if (investments) {
            setInvestmentsList(investments)
        }
        const clients = await GET("/services/admin/api/client-helper")
        if (clients) {
            setClientsList(clients)
        }
        const installments = await GET("/services/admin/api/installment-count-helper")
        if (installments) {
            setInstallsList(installments)
        }
        const conditions = await GET("/services/admin/api/condition-helper")
        if (conditions) {
            setConditionsList(conditions)
        }
    }

    const handleSubmit = (data) => {
        async function create() {
            if (investmentId) {
                const response = await PUT("/services/admin/api/agreement", data);
                if (response?.success) {
                    history.push("/admin/agreement");
                }
            } else {
                const response = await POST("/services/admin/api/agreement", data);
                if (response?.success) {
                    history.push("/admin/agreement");
                    toast.success("Успешно добавлен");
                }
            }
        }

        create();
    };

    useEffect(() => {
        if (investmentId) {
            getData();
        }
        getHelpers()
        getServerData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className="mb-1 mx-3">
            <h3 className="mb-5 mt-5">
                {investmentId ? "Изменить" : "Добавить соглашение"}
            </h3>
            <div style={{width: 500, marginLeft: 40}}>
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    <Form.Item label="Название" name="name" rules={[{required: true}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Описание" name="description">
                        <Input.TextArea/>
                    </Form.Item>
                    <Form.Item label="Инвестиция" name="investmentId" rules={[{required: true}]}>
                        <Select
                            showSearch
                            optionFilterProp="label"
                            options={map(investmentsList, (item) => {
                                return {
                                    value: get(item, "id"),
                                    label: get(item, "name"),
                                };
                            })}/>
                    </Form.Item>
                    <Form.Item label="Клиент" name="clientId" rules={[{required: true}]}>
                        <Select
                            showSearch
                            optionFilterProp="fullName"
                            options={map(clientsList, (item) => {
                                return {
                                    value: get(item, "id"),
                                    label: get(item, "firstName") + ' ' + get(item, "lastName"),
                                    fullName: `${item.firstName} ${item.lastName}`
                                };
                            })}/>
                    </Form.Item>
                    <Form.Item label="Предоплата" name="firstPayment">
                        <Input type="number"/>
                    </Form.Item>
                    <Form.Item label="Срок" name="installmentCountId" rules={[{required: true}]}>
                        <Select
                            showSearch
                            optionFilterProp="label"
                            options={map(installsList, (item) => {
                                return {
                                    value: get(item, "id"),
                                    label: get(item, "name"),
                                };
                            })}/>
                    </Form.Item>
                    <Form.Item label="Ставка" name="conditionId" rules={[{required: true}]}>
                        <Select
                            showSearch
                            optionFilterProp="label"
                            options={map(conditionsList, (item) => {
                                return {
                                    value: get(item, "id"),
                                    label: get(item, "name"),
                                };
                            })}/>
                    </Form.Item>
                    <Form.Item name="startWithCurrentDate">
                        <Radio.Group onChange={handleRadioChange}>
                            <Radio value={true}>Текущая дата</Radio>
                            <Radio value={false}>Другая дата</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="Стартовая дата" name="startDate">
                        <DatePicker
                            disabled={startWithCurrentDate === true}
                            defaultValue={dayjs(serverData)}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="default" className="mr-2" htmlType="submit">Отменить</Button>
                        <Button type="primary" htmlType="submit">Создать</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
        ;
}

export default AgreementAddUpdate;
