import React, {useState} from "react";
import {useLocation, useHistory} from "react-router-dom";
import {
    User,
    Users,
    Activity,
    ShoppingCart,
} from "react-feather";
import {
    AiOutlineAreaChart, AiOutlineCalendar,
    AiOutlineFundProjectionScreen,
    AiOutlineMenuFold as MenuFoldOutlined,
    AiOutlineMenuUnfold as MenuUnfoldOutlined, AiOutlinePercentage, AiOutlineShopping, AiOutlineTeam,
    AiOutlineUser,
    AiOutlineUser as UserOutlined,
} from 'react-icons/ai';
import {Avatar, Button, Layout, Menu, theme} from 'antd';
import {FaHandshake} from "react-icons/fa";

const {Header, Sider, Content} = Layout;

function SideBar({children}) {
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: {colorBgContainer, borderRadiusLG},
    } = theme.useToken();
    let history = useHistory();
    const {pathname, search} = useLocation();

    function signOut() {
        localStorage.removeItem("admin_tokenTime");
        localStorage.removeItem("admin_access_token");
        localStorage.removeItem("admin");
        history.push("/");
    }

    const findActiveMenu = (pathname) => {
        switch (pathname) {
            case "/loan/applications":
                return "/loan/applications";
            case "/loan/application/creat":
                return "/loan/applications";
            default:
                return pathname;
        }
    };

    const handleMenuClick = ({key}) => {
        history.push(key)
    };

    return (
        <Layout className="sidebard-height">
            <Sider trigger={null} collapsible collapsed={collapsed} className="h-100">
                <div className="dark logo-header">
                    {/*Saleinvest*/}
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    onSelect={handleMenuClick}
                    selectedKeys={search ? pathname + search : findActiveMenu(pathname)}
                    defaultSelectedKeys={pathname + search}
                    items={[
                        {
                            key: '/admin/dashboard',
                            icon: <AiOutlineAreaChart/>,
                            label: 'Статистика',
                        },
                        {
                            key: '/admin/investment',
                            icon: <AiOutlineFundProjectionScreen/>,
                            label: 'Инвестиции',
                        },   {
                            key: '/admin/agreement',
                            icon: <FaHandshake/>,
                            label: 'Договора',
                        },
                        {
                            key: '/admin/client',
                            icon: <AiOutlineTeam/>,
                            label: 'Клиенты',
                        },
                        {
                            icon: <AiOutlineUser/>,
                            label: "Пользователи",
                            key: "/admin/user",
                        },
                        {
                            icon: <AiOutlineShopping/>,
                            label: "Товары",
                            key: "/admin/products",
                        },
                        {
                            icon: <AiOutlinePercentage/>,
                            label: "Ставка",
                            key: "/admin/condition",
                        },
                        {
                            icon: <AiOutlineCalendar/>,
                            label: "Срок",
                            key: "/admin/installment",
                        },
                    ]}
                />
            </Sider>
            <Layout>
                <Header
                    style={{
                        padding: 0,
                        background: colorBgContainer,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: 'center',
                        paddingRight: 20
                    }}
                >
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        }}
                    />
                    <Avatar style={{backgroundColor: '#001628'}} icon={<UserOutlined/>}/>
                </Header>
                <Content
                    style={{
                        minHeight: 280,
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
}

export default SideBar;

