import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {DebounceInput} from "react-debounce-input";
import {PGET, PUT, GET, DELETE} from "../../../api/adminApi";
import {CustomSvg} from "../../../utils/CustomSvg";
import {get} from "lodash";
import Table from "../../ui/Table";
import InvestementDeleteModal from "./InvestementDeleteModal";
import {moneyFormatter} from "../../../utils/utilities";

function Investment() {
    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [value, setValue] = useState("");
    const [deleteModal, setDeleteModal] = useState({show: false, investment: null});

    const handleSearchInput = (e) => {
        setValue(e.target.value);
    };

    async function getItems() {
        const data = await PGET("/services/admin/api/investment-pageList", true, {
            page: page - 1,
            size: 10,
            search: value,
        });
        setTotalCount(Math.ceil(data.headers["x-total-count"]));
        setItems(data);
    }

    async function deleteItem(id) {
        await DELETE("/services/admin/api/investment/" + id);
        getItems();
    }

    useEffect(() => {
        getItems();
    }, [page, value])

    const columns = [
        {
            title: "№",
            key: "id",
            dataIndex: "id",
            align: "center",
            render: (id, item, index) => (
                <div className="text-center">
                    {page === 1 ? index + 1 : page * 10 + index + 1}
                </div>
            ),
        },
        {
            title: "Названия",
            key: "name",
            dataIndex: "name",
            align: "left",
            render: (name, item) => {
                console.log(item)
                return (
                    <div className="text-left">
                        <Link to={`investment/history/${item.id}`} >
                            {name}
                        </Link>
                    </div>
                )
            }
        },
        {
            title: "Дата создания",
            key: "createdTime",
            align: "right",
            dataIndex: "createdTime",
            render: (item) => (
                <div className="text-right">
                    {item}
                </div>
            ),
        },
        {
            title: "Сумма инвестиций",
            key: "investmentSum",
            dataIndex: "investmentSum",
            align: "right",
            render: (item) => (
                <div className="text-right">
                    {moneyFormatter(item)}
                </div>
            ),
        },
        {
            title: "Описание",
            key: "description",
            dataIndex: "description",
            align: "right",
            render: (item) => (
                <div className="text-right">
                    {item}
                </div>
            ),
        },
        {
            title: "Действия",
            key: "actions",
            dataIndex: "id",
            align: "center",
            render: (item) => (
                <div className="text-center">
                    <Link
                        to={"/admin/investment/update/" + item.id}
                        className='btn btn-icon btn-active-color-primary btn-sm me-1 shadow-sm'
                    >
                        <CustomSvg path='/icons/duotune/art/art005.svg' className='svg-icon-3'/>
                    </Link>
                    <button
                        onClick={() => deleteItem(item.id)}
                        className='btn btn-icon shadow-sm btn-active-color-primary btn-sm'
                    >
                        <CustomSvg path='/icons/duotune/general/gen027.svg' className='svg-icon-3'/>
                    </button>
                </div>
            ),
        },
    ];

    return (
        <div className="br-5 card" style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1' style={{position: "absolute", left: 20}}>Инвестиции</span>
                </h3>
                <div className='card-toolbar' data-bs-toggle='tooltip' data-bs-placement='top' data-bs-trigger='hover'
                     title='Click to add a user'>
                    <Link to="/admin/investment/create" className='btn btn-sm btn-light-primary' data-bs-toggle='modal'
                          data-bs-target='#kt_modal_invite_friends'>
                        <CustomSvg path='/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/> Добавить новый
                    </Link>
                </div>
            </div>
            <div className="px-3 pb-4 pt-2">
                <div className="mb-4 d-flex justify-content-between">
                    <div className="d-flex gap-4">
                        <div className="position-relative">
                            <DebounceInput
                                minLength={3}
                                debounceTimeout={400}
                                value={value}
                                onChange={handleSearchInput}
                                type="text"
                                className="input-search search-icon br-5"
                                size={24}
                                placeholder="Поиск"
                            />
                        </div>
                    </div>
                </div>
                <Table
                    page={page}
                    setPage={setPage}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    data={items?.data}
                    totalElements={totalCount}
                    columns={columns}
                />
            </div>
            {deleteModal.investment && (
                <InvestementDeleteModal deleteModal={deleteModal} setDeleteModal={setDeleteModal} getItems={getItems}/>
            )}
        </div>
    );
}

export default Investment;
