import {DELETE, excelParams, GET, PGET} from "../../../api/adminApi";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Table from "../../ui/Table";
import {Col, Input, Row, Select} from "antd";
import Operation from "./Operation";
import {CustomSvg} from "../../../utils/CustomSvg";
import {get} from "lodash";
import {moneyFormatter} from "../../../utils/utilities";
import {FaArrowDown, FaArrowUp} from "react-icons/fa";

const {Search} = Input;

const installmentHelper = [
    {
        id: 0,
        name: "Все"
    },
    {
        id: 1,
        name: "Инвестиции"
    },
    {
        id: 2,
        name: "Договора"
    },
]
export default function InvestmentHistory() {
    const {id} = useParams()
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const [serverData, setServerData] = useState()
    const [totalCount, setTotalCount] = useState()
    const [investment, setInvestment] = useState({id: 0, name: "Все"});
    const [investments, setInvestments] = useState([investment]);
    const [installment, setInstallment] = useState({id: 0, name: "Все"});
    const [value, setValue] = useState("");

    async function getInvestments() {
        try {
            const response = await GET("/services/admin/api/investment-helper");
            if (response?.length > 0) {
                setInvestments([investment, ...response]);
            }
        } catch (error) {
            console.error('Failed to fetch investments', error);
        }
    }
    const handleChangeInvestment = (value) => {
        const selectedInvestment = investments.find(inv => inv.id === value.value);
        setInvestment(selectedInvestment);
    };

    const handleChangeInstallment = (value) => {
        const selectedInstallment = installmentHelper.find(inv => inv.id === value);
        setInstallment(selectedInstallment);
    };
    async function getServerData() {
        const response = await GET("/services/admin/api/agreement-current-date");
        if (response) {
            console.log(response)
            setServerData(response)
        }
    }
    const handleSearchInput = (e) => {
        setValue(e.target.value);
    };
    async function getItems() {
        const data = await PGET("/services/admin/api/investment-sum/", true, {
            page: page - 1,
            size: perPage,
            investmentId: id,
            transactionType: installment.id
        });
        setTotalCount(Math.ceil(data.headers["x-total-count"]));
        setItems(data.data);
    }
    function weekOwnerAgents() {
        const excelUrl = 'https://bo.saleinvest.uz/services/admin/api/investment-sum/download-all';
        const fileName = 'investment-history.xlsx'
        excelParams(excelUrl, fileName, true, {
            investmentId: id,
            transactionType: installment.id,
        });
    }
    async function deleteItem(id) {
        await DELETE("/services/admin/api/investment-sum/" + id);
        getItems();
    }
    useEffect(() => {
        getInvestments()
        getServerData()
    }, []);

    const columns = [
        {
            title: "№",
            key: "id",
            align: "center",
            dataIndex: "id",
            render: (id, item, index) => (
                <div className="text-right">
                    {page === 1 ? index + 1 : page * 10 + index + 1}
                </div>
            ),
        },
        {
            title: "Время",
            align: "right",
            key: "changedDate",
            dataIndex: "changedDate",
            render: (item) => (
                <div className="text-right">
                    {item}
                </div>
            ),
        },
        {
            title: "Сумма операции",
            key: "amount",
            align: "right",
            dataIndex: "amount",
            render: (amount) => {
                const iconStyle = { color: amount < 0 ? 'red' : 'green', marginRight: 5 };
                return (
                    <div className="text-right">
                        {amount < 0 ? (
                            <FaArrowDown style={iconStyle} />
                        ) : (
                            <FaArrowUp style={iconStyle} />
                        )}
                        {moneyFormatter(amount)}
                    </div>
                );
            },
        },
        {
            title: "Описание",
            key: "description",
            align: "right",
            dataIndex: "description",
            render: (item) => (
                <div className="text-right">
                    {item}
                </div>
            ),
        },
        {
            title: "Договор",
            align: "left",
            key: "agreementName",
            dataIndex: "agreementName",
            render: (item) => (
                <div className="text-left">
                    {item}
                </div>
            ),
        }, {
            title: "Предыдущая сумма",
            key: "previousAmount",
            align: "right",
            dataIndex: "previousAmount",
            render: (item) => (
                <div className="text-right">
                    {moneyFormatter(item)}
                </div>
            ),
        },
        {
            title: "Текущая сумма",
            key: "lastAmount",
            align: "right",
            dataIndex: "lastAmount",
            render: (item) => (
                <div className="text-right">
                    {moneyFormatter(item)}
                </div>
            ),
        },
        // {
        //     title: "Действия",
        //     key: "actions",
        //     align: "right",
        //     dataIndex: "id",
        //     render: (item) => (
        //         <div className="text-right">
        //             <div className='d-flex justify-content-end flex-shrink-0'>
        //                 <Link
        //                     to={"/admin/investment/update/" + item.id}
        //                     href='#'
        //                     className='btn btn-icon btn-active-color-primary btn-sm me-1 shadow-sm'
        //                 >
        //                     <CustomSvg path='/icons/duotune/art/art005.svg' className='svg-icon-3'/>
        //                 </Link>
        //                 <button
        //                     onClick={() => deleteItem(item.id)}
        //                     className='btn btn-icon shadow-sm btn-active-color-primary btn-sm'
        //                 >
        //                     <CustomSvg
        //                         path='/icons/duotune/general/gen027.svg'
        //                         className='svg-icon-3'
        //                     />
        //                 </button>
        //             </div>
        //         </div>
        //     ),
        // },
    ]

    useEffect(() => {
        getItems()
    }, [page, installment, investment, perPage, value]);
    return (
        <div className="p-5">
            <div className="flex justify-between">
                <h3 className="my-5">История инвестиций ({get(items, '[0].investmentName')})</h3>
                <Operation open={open} setOpen={setOpen} getItems={getItems} serverData={serverData}/>
            </div>
            <div className="pb-4 pt-2 flex justify-between items-center">
                <div className="w-100">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={8} lg={4}>
                            <p className="p-0 m-0 text-black">Поиск</p>
                            <Search
                                value={value}
                                onChange={handleSearchInput}
                                placeholder="Поиск"
                                enterButton
                                allowClear
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={4}>
                            <p className="p-0 m-0 text-black">Инвестиции</p>
                            <Select
                                labelInValue="test"
                                style={{width: '100%'}}
                                value={investment.id}
                                onChange={handleChangeInvestment}
                            >
                                {investments.map(inv => (
                                    <Select.Option key={inv.id} value={inv.id}>
                                        {inv.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={4}>
                            <p className="p-0 m-0 text-black">Тип операции</p>
                            <Select
                                style={{width: '100%'}}
                                value={installment.id}
                                onChange={handleChangeInstallment}
                            >
                                {installmentHelper.map(inv => (
                                    <Select.Option key={inv.id} value={inv.id}>
                                        {inv.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </div>
                <div className="d-flex">
                    <button
                        onClick={weekOwnerAgents}
                        className="mr-0 box-shadow"
                        style={{
                            backgroundColor: "#fcfcfc",
                            height: 38,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid rgb(212, 211, 214)",
                            borderRadius: 5,
                            marginTop: 10,
                            padding: "0 10px",
                            width: 150,
                        }}
                    >
                        <CustomSvg path='/icons/duotune/files/fil017.svg' className='svg-icon-3 mr-2'/>
                        Скачать все
                    </button>
                </div>
            </div>
            <Table
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                data={items}
                totalElements={totalCount}
                columns={columns}
            />
        </div>
    )
}