import { useEffect, useState, useMemo } from "react";
import { GET, PGET } from "../../../api/adminApi";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import { Select, Row, Col } from "antd";
import moment from "moment";
import DatePicker from "react-datepicker";

export const BarChartOne = ({ options, height, layout }) => {
    const [dashboardData, setDashboardData] = useState([]);
    const [dateRange, setDateRange] = useState([
        new Date('2023-01-01'),
        new Date('2025-01-01'),
    ]);
    const [startDate, endDate] = dateRange;
    const [investment, setInvestment] = useState({ id: 0, name: "Все" });
    const [investments, setInvestments] = useState([investment]);

    async function getInvestments() {
        try {
            const response = await GET("/services/admin/api/investment-helper");
            if (response?.length > 0) {
                setInvestments([investment, ...response]);
            }
        } catch (error) {
            console.error('Failed to fetch investments', error);
        }
    }

    const handleChangeInvestment = (value) => {
        const selectedInvestment = investments.find(inv => inv.id === value);
        setInvestment(selectedInvestment);
    };

    async function getDashboardMonthCounts() {
        try {
            if (dateRange[0] && dateRange[1]) {
                const data = await PGET("/services/admin/api/dashboard-month-counts/", true, {
                    investmentId: investment.id,
                    dateFrom: moment(startDate).format("YYYY-MM-DD"),
                    dateTo: moment(endDate).add(1, 'day').format("YYYY-MM-DD"),
                });
                if (data) {
                    setDashboardData(data.data);
                }
            }
        } catch (error) {
            console.error('Failed to fetch dashboard data', error);
        }
    }

    console.log(dashboardData)
    const chartData = useMemo(() => ({
        labels: dashboardData.map(item => (item.month)),
        datasets: [
            {
                label: "Оплаты",
                data: dashboardData.map(item => item.totalPayment),
                backgroundColor: "rgba(255, 99, 132, 0.5)",
            },
            {
                label: "Прибыль",
                data: dashboardData.map(item => item.totalProfit),
                backgroundColor: "rgba(53, 162, 235, 0.5)",
            }
        ],
    }), [dashboardData]);

    useEffect(() => {
        getInvestments();
    }, []);

    useEffect(() => {
        getDashboardMonthCounts();
    }, [investment,startDate, endDate]);

    return (
        <div style={{ padding: '20px' }}>
            <Row gutter={[16, 16]} justify="end">
                <Col xs={24} sm={12} md={8} lg={6}>
                    <Select
                        style={{ width: '100%' }}
                        value={investment.id}
                        onChange={handleChangeInvestment}
                    >
                        {investments.map(inv => (
                            <Select.Option key={inv.id} value={inv.id}>
                                {inv.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6}>
                    <DatePicker
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            setDateRange(update);
                        }}
                        isClearable={true}
                        className="datepicker-custom-input"
                    />
                </Col>
            </Row>
            <Bar data={chartData} height={height} options={{ ...options, ...layout }} />
        </div>
    );
};

BarChartOne.defaultProps = {
    options: {
        maintainAspectRatio: true,
        responsive: true,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            },
        },
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
            },
        },
    },
};

BarChartOne.propTypes = {
    height: PropTypes.number,
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.object),
    options: PropTypes.object,
    layout: PropTypes.object,
};
