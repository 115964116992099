import {HashRouter as Router, Switch, Route} from "react-router-dom";
import {useSelector} from "react-redux";
import {ToastContainer} from "react-toastify";

import Header from "./components/front/Header";
import Footer from "./components/front/Footer";
import Checkout from "./components/front/Checkout";
import Detail from "./components/front/Detail";
import Basket from "./components/front/Basket";
import Profile from "./components/front/Profile";

// Admin
import SideBar from "./components/admin/SideBar";
import AdminLogin from "./components/admin/Login";
// CSS
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "react-select2-wrapper/css/select2.css";
import "./css/bootstrap.min.css";
import "./css/bootstrap.css";
import "./css/App.css";

import {
    PrivateRoute,
    AdminPrivateRoute,
} from "./private";
import Investment from "./components/admin/Investment/Investment";
import InvestmentAddUpdate from "./components/admin/Investment/InvestmentAddUpdate";
import User from "./components/admin/Users/User";
import UserAddUpdate from "./components/admin/Users/UserAddUpdate";
import Products from "./components/admin/Products/Products";
import ProductsAddUpdate from "./components/admin/Products/ProductsAddUpdate";
import Client from "./components/admin/Clients/Client";
import ClientAddUpdate from "./components/admin/Clients/ClientAddUpdate";
import Dashboard from "./components/admin/dashboard/Dashboard";
import Installment from "./components/admin/installment/Installment"
import InstallmentAddUpdate from "./components/admin/installment/InstallmentAddUpdate";
import Condition from "./components/admin/condition/Condition"
import ConditionAddUpdate from "./components/admin/condition/ConditionAddUpdate";
import Agreement from "./components/admin/agreement/AgreementList/Agreement";
import AgreementAddUpdate from "./components/admin/agreement/AgreementAddUpdate";
import AgreementDetail from "./components/admin/agreement/AgreementDetail";
import InvestmentHistory from "./components/admin/Investment/InvestmentHistory";
import AgreementPayment from "./components/admin/agreement/AgreementPayment";
import AgreementHistory from "./components/admin/agreement/AgreementHistory";

function App() {
    const loader = useSelector((state) => state.loader);
    return (
        <>
            <ToastContainer
                closeOnClick={false}
                hideProgressBar={false}
                newestOnTop={false}
            />
            {loader && (
                <div id="loading-bg">
                    <div className="loading">
                        <div className="spinner-border text-purple"></div>
                    </div>
                </div>
            )}
            <Router>
                <Switch>
                    <Route path="/admin/login" component={AdminLogin}/>

                    <Route path="/admin/:path?" exact>
                        <SideBar>
                            <Switch>
                                <AdminPrivateRoute
                                    path="/admin/dashboard"
                                    exact
                                    component={Dashboard}
                                />
                                <AdminPrivateRoute
                                    path="/admin/investment"
                                    exact
                                    component={Investment}
                                />
                                <AdminPrivateRoute
                                    path="/admin/user"
                                    exact
                                    component={User}
                                />
                                <AdminPrivateRoute
                                    path="/admin/products"
                                    exact
                                    component={Products}
                                />
                                <AdminPrivateRoute
                                    path="/admin/client"
                                    exact
                                    component={Client}
                                />
                                <AdminPrivateRoute
                                    path="/admin/installment"
                                    exact
                                    component={Installment}
                                />
                                <AdminPrivateRoute
                                    path="/admin/condition"
                                    exact
                                    component={Condition}
                                />
                                <AdminPrivateRoute
                                    path="/admin/agreement"
                                    exact
                                    component={Agreement}
                                />
                            </Switch>
                        </SideBar>
                    </Route>

                    <Route path="/admin/:path/:path" exact>
                        <SideBar>
                            <Switch>
                                {/*  */}
                                <AdminPrivateRoute
                                    path="/admin/investment/create"
                                    exact
                                    component={InvestmentAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/client/create"
                                    exact
                                    component={ClientAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/user/create"
                                    exact
                                    component={UserAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/product/create"
                                    exact
                                    component={ProductsAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/installment/create"
                                    exact
                                    component={InstallmentAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/condition/create"
                                    exact
                                    component={ConditionAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/agreement/create"
                                    exact
                                    component={AgreementAddUpdate}
                                />
                            </Switch>
                        </SideBar>
                    </Route>

                    <Route path="/admin/:path/:path/:path?" exact>
                        <SideBar>
                            <Switch>
                                <AdminPrivateRoute
                                    path="/admin/agreement/payments/:id"
                                    exact
                                    component={AgreementPayment}
                                />
                                <AdminPrivateRoute
                                    path="/admin/investment/update/:id"
                                    exact
                                    component={InvestmentAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/user/update/:id"
                                    exact
                                    component={UserAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/product/update/:id"
                                    exact
                                    component={ProductsAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/client/update/:id"
                                    exact
                                    component={ClientAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/installment/update/:id"
                                    exact
                                    component={InstallmentAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/condition/update/:id"
                                    exact
                                    component={ConditionAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/agreement/update/:id"
                                    exact
                                    component={AgreementAddUpdate}
                                />
                                <AdminPrivateRoute
                                    path="/admin/agreement/history/:id"
                                    exact
                                    component={AgreementHistory}
                                />
                                <AdminPrivateRoute
                                    path="/admin/agreement/detail/:id"
                                    exact
                                    component={AgreementDetail}
                                />
                                <AdminPrivateRoute
                                    path="/admin/investment/history/:id"
                                    exact
                                    component={InvestmentHistory}
                                />
                                <AdminPrivateRoute
                                    path="/admin/history/payment/:id"
                                    exact
                                    component={InvestmentAddUpdate}
                                />
                            </Switch>
                        </SideBar>
                    </Route>

                    <Route path="/admin/:path/:path/:path/:id" exact>
                        <SideBar>
                            <Switch>

                            </Switch>
                        </SideBar>
                    </Route>

                    {/* OPERATOR */}
                    <Route path="/:path?" exact>
                        <PrivateRoute path="/checkout" component={Checkout}/>
                        <Route path="/" exact component={AdminLogin}/>
                        <Route path="/basket" exact component={Basket}/>
                        <PrivateRoute path="/profile" exact component={Profile}/>
                    </Route>

                    <Route path="/:path/:path?" exact>
                        <div className="d-flex flex-column min-vh-100">
                            <Header/>
                            <div style={{marginTop: "75px"}}>
                                <Route path="/detail/:id" exact component={Detail}/>
                            </div>

                            <div className="mt-auto">
                                <Footer/>
                            </div>
                        </div>
                    </Route>
                </Switch>
            </Router>
        </>
    );
}

export default App;
