import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Form, Input, InputNumber, Modal, Radio, Select} from 'antd';
import {CiCirclePlus} from "react-icons/ci";
import {GET, POST} from "../../../api/adminApi";
import {useParams} from "react-router-dom";
import {get, map} from "lodash";
import {moneyFormatter} from "../../../utils/utilities";

const MonthlyPayment = ({getItems, selectedIds, setSelectedIds}) => {
    const [open, setOpen] = useState()
    const {id} = useParams()
    const [form] = Form.useForm()
    const [confirmLoading, setConfirmLoading] = useState(false);
    const showModal = () => {
        setOpen(true);
    };
    const handleSubmit = async (data) => {

        const obj = {
            paymentAmount: 0,
            agreementId: Number(id),
            agreementInstallmentId: selectedIds
        }

        setConfirmLoading(true);
        const res = await POST("/services/admin/api/agreement-installment-pay-month-multiple", obj);
        if (res) {
            getItems()
            setSelectedIds([])
            form.resetFields()
            setOpen(false);
            setConfirmLoading(false);
        }
    };
    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <>
            <Button disabled={selectedIds.length === 0} onClick={showModal}
                    type="primary">Оплатить {selectedIds.length > 0 ? `по выбранным ${selectedIds.length} датам` : ''}</Button>
            <Modal
                title={`Оплата по договору ${id}`}
                open={open}
                onOk={() => form.submit()}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                {/*    <Form.Item*/}
                {/*        label="Сумма"*/}
                {/*        name="paymentAmount"*/}
                {/*        rules={[*/}
                {/*            {required: true},*/}
                {/*            {*/}
                {/*                validator: async (_, value) => {*/}
                {/*                    if (value <= 100000000000) {*/}
                {/*                        return Promise.resolve();*/}
                {/*                    }*/}
                {/*                    return Promise.reject(new Error(`Введите ниже ${moneyFormatter(100000000000)}`));*/}
                {/*                }*/}
                {/*            }*/}
                {/*        ]}*/}
                {/*    >*/}
                {/*        <InputNumber*/}
                {/*            style={{width: '100%'}}*/}
                {/*            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                {/*            parser={value => value.replace(/\$\s?|(,*)/g, '')}*/}
                {/*        />*/}
                {/*    </Form.Item>*/}
                </Form>
            </Modal>
        </>
    );
};
export default MonthlyPayment;