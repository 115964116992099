import React, {useEffect} from "react";
import {Table} from "antd";
import {clsx} from "clsx";
import {isEqual, isEmpty, get} from "lodash";

const Index = ({
                   data,
                   isLoading,
                   columns,
                   page,
                   setPage,
                   perPage,
                   setPerPage,
                   totalElements,
                   setSorter,
                   onRow = () => {
                   },
               }) => {
    const scrollable = (isEmpty, isLoading) => {
        if (isLoading) {
            return {};
        } else {
            return {x: 1028};
        }
    };

    useEffect(() => {
        const body = document.querySelector("#root");
        body.scrollIntoView(
            {
                behavior: "smooth",
            },
            500,
        );
    }, [page]);

    return (
        <div className={"mt-2"}>
            <Table
                pagination={{
                    current: page,
                    pageSize: perPage,
                    total: totalElements,
                    onChange: (page, pageSize) => {
                        setPage(page);
                        setPerPage(pageSize);
                    },
                }}
                scroll={scrollable(isEmpty(data), isLoading)}
                columns={columns}
                loading={isLoading}
                dataSource={data}
                onChange={(pagination, filters, sorter, extra) => {
                    setSorter({
                        sortField: get(sorter, "field"),
                        sortDirection: isEqual(get(sorter, "order"), "ascend")
                            ? "ASC"
                            : "DESC",
                    });
                }}
                onRow={onRow}
                bordered
                className={clsx(
                    isLoading || (isEmpty(data) && "table-full-height"),
                    isLoading && "table-loading",
                )}
                rowClassName={"cursor-pointer"}
            />
        </div>
    );
};

export default Index;
