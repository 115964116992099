import React, {useState, useEffect} from "react";
import {useParams, useHistory, Link} from "react-router-dom";
import {XCircle, Save} from "react-feather";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import {POST, GET, PUT} from "../../../api/adminApi";

function InvestmentAddUpdate() {
    const {id: investmentId} = useParams();
    const history = useHistory();
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
    } = useForm();
    const [data, setData] = useState({
        approved: 0,
    });

    function onSubmit(values) {
        const obj = {
            ...data,
            name: values.name,
            description: values.description,
            color: values.color,
        };

        async function create() {
            if (investmentId) {
                const response = await PUT("/services/admin/api/investment", obj);
                if (response?.success) {
                    history.push("/admin/investment");
                }
            } else {
                const response = await POST("/services/admin/api/investment", obj);
                if (response?.success) {
                    history.push("/admin/investment");
                    toast.success("Успешно добавлен");
                }
            }
        }

        create();
    }


    async function getData() {
        const response = await GET("/services/admin/api/investment/" + investmentId);
        if (response) {
            const {
                id,
                name,
                description,
                color,
            } = response;
            setValue("name", name);
            setValue("description", description);
            setValue("color", color);
            setData((prev) => ({
                ...prev,
                id,
            }));
        }
    }

    useEffect(() => {
        if (investmentId) {
            getData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="mb-1 mx-3">
            <h3 className="mb-5 mt-5">
                {investmentId ? "Изменить" : "Добавить инвестицию"}
            </h3>

            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="admin-card p-4 overflow-y-auto pt-12 d-flex flex-column justify-content-between">
                    <div className="mb-1 d-flex">
                        <div className="">
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Название
                                </label>
                                <div>
                                    <input
                                        {...register("name")}
                                        type="text"
                                        className="input-control"
                                        style={{width: "300px"}}
                                    />
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Описание
                                </label>
                                <div>
                           <textarea
                               {...register("description")}
                               type="text"
                               className="input-control"
                               style={{width: "300px"}}
                           />
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-2 gap-2">
                                <label
                                    style={{width: "120px"}}
                                    className="color-grey text-end text-nowrap"
                                >
                                    Цвет
                                </label>
                                <div>
                                    <input
                                        {...register("color")}
                                        type="text"
                                        className="input-control"
                                        style={{width: "300px"}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end">
                        <Link to="/admin/investment" className="mr-3">
                            <button className="btn text-white btn-bg-primary d-flex align-items-center">
                                <XCircle size={14} className="mr-2"/>
                                Отмена
                            </button>
                        </Link>
                        <button
                            className="btn text-white btn-bg-success d-flex align-items-center"
                            type="submit"
                        >
                            <Save size={14} className="mr-2"/>
                            Сохранить
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
        ;
}

export default InvestmentAddUpdate;
