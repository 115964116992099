import React, {useState} from 'react';
import {Button,  Form, Modal,} from 'antd';
import {POST} from "../../../api/adminApi";
import {IoMdClose} from "react-icons/io";

const AgreementHistoryCancel = ({id}) => {
    const [open, setOpen] = useState()
    const [form] = Form.useForm()
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = () => {
        setOpen(true);
    };
    const handleSubmit = async () => {
        setConfirmLoading(true);
        const res = await POST("/services/admin/api/agreement-revert-payment", {id});
        if (res) {
            form.resetFields()
            setOpen(false);
            setConfirmLoading(false);
        }
    };
    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <>
            <Button onClick={showModal} type="primary" className="bg-green mr-2 flex items-center">
                <IoMdClose size={20}/>
                Отменить</Button>
            <Modal
                title={`Вы действительно хотите отменить платеж?`}
                open={open}
                onOk={() => handleSubmit()}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
            </Modal>
        </>
    );
};
export default AgreementHistoryCancel;