import React, {useState} from 'react';
import {Button, DatePicker, Form, Input, InputNumber, Modal, Select} from 'antd';
import {CiCirclePlus} from "react-icons/ci";
import {POST} from "../../../api/adminApi";
import {useParams} from "react-router-dom";
import {moneyFormatter} from "../../../utils/utilities";
import dayjs from "dayjs";
import {get} from "lodash";

const Operation = ({open, setOpen, getItems, serverData}) => {
    const {id} = useParams()
    const [form] = Form.useForm()
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [date, setDate] = useState(get(serverData, 'currentLocalDate', dayjs))
    const showModal = () => {
        setOpen(true);
    };
    const handleSubmit = async (data) => {

        let dataSend;

        if (data.operation === "minus" || data.operation === "minus-divident") {
            dataSend = {
                ...data,
                amount: -Math.abs(Number(data.amount)),
                investmentId: Number(id),
                changeDate: data.changeDate.format("YYYY-MM-DD"),
                operation: 'minus',
                type: data.operation === "minus-divident" ? 1 : 0
            };
        } else if (data.operation === "plus" || data.operation === "plus-divident") {
            dataSend = {
                ...data,
                amount: Math.abs(Number(data.amount)),
                investmentId: Number(id),
                changeDate: data.changeDate.format("YYYY-MM-DD"),
                operation: 'plus',
                type: data.operation === "plus-divident" ? 1 : 0
            };
        }

        setConfirmLoading(true);
        const res = await POST("/services/admin/api/investment-sum", dataSend);
        if (res) {
            getItems()
            form.resetFields()
            setOpen(false);
            setConfirmLoading(false);
        }
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                className=""
                type="primary"
                onClick={showModal}
                icon={<CiCirclePlus size="20px"/>}
            >
                Изменение
            </Button>
            <Modal
                title="Title"
                open={open}
                onOk={() => form.submit()}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Form form={form} onFinish={handleSubmit} layout="vertical" initialValues={{
                    changeDate: date
                }}>
                    <Form.Item label="Операция" name="operation" rules={[{required: true}]}>
                        <Select
                            showSearch
                            optionFilterProp="label"
                            options={[
                                {
                                    label: "Снять",
                                    value: "minus"
                                },
                                {
                                    label: "Добавить",
                                    value: "plus"
                                },
                                {
                                    label: "Снять дивиденд",
                                    value: "minus-divident"
                                },
                                {
                                    label: "Вернуть дивиденд",
                                    value: "plus-divident"
                                }
                                ]
                            }/>
                    </Form.Item>
                    <Form.Item
                        label="Сумма"
                        name="amount"
                        rules={[
                            {required: true},
                            {
                                validator: async (_, value) => {
                                    if (value <= 100000000000) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(`Введите ниже ${moneyFormatter(100000000000)}`));
                                }
                            }
                        ]}
                    >
                        <InputNumber
                            style={{width: '100%'}}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                    <Form.Item label="Дата" name="changeDate">
                        <DatePicker
                            value={date}
                            onChange={(e) => setDate(e)}
                        />
                    </Form.Item>
                    <Form.Item label="Описание" name="description" rules={[{required: true}]}>
                        <Input.TextArea/>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default Operation;