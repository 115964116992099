import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {PGET, DELETE, GET, excelParams} from "../../../../api/adminApi";
import AgreementDeleteModal from "../AgreementDeleteModal";
import {CustomSvg} from "../../../../utils/CustomSvg";
import {moneyFormatter} from "../../../../utils/utilities";
import {Col, Input, Row, Select, Tag} from "antd";
import Table from "../../../ui/Table";
import {get} from "lodash";

const {Search} = Input;

const paymentStatuses = [
    {id: 0, name: "Все"},
    {id: 1, name: "Неоплаченные вовремя"}
]
function Agreement() {
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [count, setCount] = useState(10);
    const [value, setValue] = useState("");
    const [deleteModal, setDeleteModal] = useState({
        show: false, investment: null,
    });
    const [investment, setInvestment] = useState({id: 0, name: "Все"});
    const [investments, setInvestments] = useState([investment]);
    const [installment, setInstallment] = useState({id: 0, name: "Все", monthCount: 0});
    const [installments, setInstallments] = useState([installment]);
    const [condition, setCondition] = useState({id: 0, name: "Все", percentage: 0});
    const [conditions, setConditions] = useState([condition]);
    const [status, setStatus] = useState({id: 0, name: "Все"});
    const [statuses, setStatuses] = useState([status]);
    const [sorter, setSorter] = useState({ sortField: "name", sortDirection: "ASC" });
    const [paymentStatus, setPaymentStatus] = useState({id: 0, name: "Все"});

    async function getInvestments() {
        try {
            const response = await GET("/services/admin/api/investment-helper");
            if (response?.length > 0) {
                setInvestments([investment, ...response]);
            }
        } catch (error) {
            console.error('Failed to fetch investments', error);
        }
    }

    async function getInstallments() {
        try {
            const response = await GET("/services/admin/api/installment-count-helper");
            if (response?.length > 0) {
                setInstallments([installment, ...response]);
            }
        } catch (error) {
            console.error('Failed to fetch investments', error);
        }
    }

    async function getConditions() {
        try {
            const response = await GET("/services/admin/api/condition-helper");
            if (response?.length > 0) {
                setConditions([condition, ...response]);
            }
        } catch (error) {
            console.error('Failed to fetch investments', error);
        }
    }

    async function getStatus() {
        try {
            const response = await GET("/services/admin/api/agreement-status-helper");
            if (response?.length > 0) {
                setStatuses([status, ...response]);
            }
        } catch (error) {
            console.error('Failed to fetch status', error);
        }
    }

    const handleChangeInvestment = (value) => {
        const selectedInvestment = investments.find(inv => inv.id === value.value);
        setInvestment(selectedInvestment);
    };

    const handleChangeInstallment = (value) => {
        const selectedInstallment = installments.find(inv => inv.id === value);
        setInstallment(selectedInstallment);
    };

    const handleChangeCondition = (value) => {
        const selected = conditions.find(inv => inv.id === value);
        setCondition(selected);
    };
    const handleChangeStatus = (value) => {
        const selected = statuses.find(inv => inv.id === value);
        setStatus(selected);
    };
    const handleChangePaymentStatus = (value) => {
        const selected = paymentStatuses.find(inv => inv.id === value);
        setPaymentStatus(selected);
    };
    const handleSearchInput = (e) => {
        setValue(e.target.value);
    };

    async function getItems() {
        const data = await PGET("/services/admin/api/agreement-pageList/", true, {
            page: page - 1,
            size: perPage,
            search: value,
            investmentId: investment.id,
            statusId: status.id,
            monthCount: installment.monthCount,
            percentage: condition.percentage,
            order: sorter.sortDirection,
            sortBy: sorter.sortField,
            paymentStatus: paymentStatus.id
        });
        setCount(Math.ceil(data?.headers["x-total-count"]));
        setItems(data);
    }

    async function deleteItem(id) {
        await DELETE("/services/admin/api/investment/" + id);
        getItems();
    }

    useEffect(() => {
        getInvestments()
        getInstallments()
        getConditions()
        getStatus()
    }, []);

    useEffect(() => {
        getItems();
    }, [page, value, condition, investment, installment, status, paymentStatus, sorter]); // eslint-disable-line react-hooks/exhaustive-deps
    function weekOwnerAgents() {
        const excelUrl = '/services/admin/api/agreement/download-all';
        const fileName = 'agreement.xlsx'
        excelParams(excelUrl, fileName, true, {
            investmentId: investment.id,
            statusId: status.id,
            monthCount: installment.monthCount,
            percentage: condition.percentage,

        });
    }

    const columns = [
        {
            title: "№",
            key: "id",
            dataIndex: "id",
            align: "center",
            render: (id, item, index) => (
                <div className="text-center">
                    {page === 1 ? index + 1 : page * 10 + index + 1}
                </div>
            ),
        },
        {
            title: "Договор",
            key: "name",
            align: "left",
            dataIndex: "name",
            sorter: true,
            render: (name, item) => {
                return (
                    <div className="text-left">
                        <Link to={`agreement/detail/${item.id}`}>
                            {name}
                        </Link>
                    </div>

                )
            },
        },
        {
            title: "Клиент",
            key: "clientName",
            align: "right",
            dataIndex: "clientName",
            sorter: true,
            render: (item) => (
                <div className="text-right">
                    {item}
                </div>
            ),
        },
        {
            title: "Условия",
            key: "installmentMonthCount",
            align: "right",
            dataIndex: "installmentMonthCount",
            sorter: true,
            render: (item) => (
                <div className="text-right">
                    {item} месяцев
                </div>
            ),
        },
        {
            title: "Ставка",
            align: "right",
            key: "conditionName",
            dataIndex: "conditionName",
            sorter: true,
            render: (item) => (
                <div className="text-right">
                    {item}
                </div>
            ),
        },
        {
            title: "Начало",
            align: "right",
            key: "startDate",
            dataIndex: "startDate",
            sorter: true,
            render: (item) => (
                <div className="text-right">
                    {item}
                </div>
            ),
        },
        {
            title: "Общая цена",
            key: "totalPrice",
            align: "right",
            sorter: true,
            dataIndex: "totalPrice",
            render: (item) => (
                <div className="text-right">
                    {moneyFormatter(item)}
                </div>
            ),
        },
        {
            title: "Предоплата",
            key: "firstPayment",
            align: "right",
            dataIndex: "firstPayment",
            sorter: true,
            render: (item) => (
                <div className="text-right">
                    {moneyFormatter(item)}
                </div>
            ),
        },
        {
            title: "К оплате",
            key: "totalForPayment",
            align: "right",
            sorter: true,
            dataIndex: "totalForPayment",
            render: (item) => (
                <div className="text-right">
                    {moneyFormatter(item)}
                </div>
            ),
        },
        {
            title: "Оплачено",
            align: "right",
            key: "alreadyPaid",
            sorter: true,
            dataIndex: "alreadyPaid",
            render: (item) => (
                <div className="text-right">
                    {moneyFormatter(item)}
                </div>
            ),
        },
        {
            title: "Ежемесячно",
            align: "right",
            key: "nextInstallmentAmount",
            sorter: true,
            dataIndex: "nextInstallmentAmount",
            render: (item) => (
                <div className="text-right">
                    {moneyFormatter(item)}
                </div>
            ),
        },
        {
            title: "Статус",
            key: "statusId",
            sorter: true,
            align: "right",
            dataIndex: "statusId",
            render: (statusId) => (
                <div className="text-right">
                    <p className="ml-2">
                        {
                            statusId === 1 ?
                                <Tag color="blue">Новый</Tag> :
                                statusId === 2 ?
                                    <Tag color="green">Активный</Tag> :
                                    statusId === 3 ?
                                        <Tag color="red">Закрытый</Tag> :
                                        statusId === 4 ?
                                            <Tag color="yellow">Отмененный</Tag> : '-'
                        }
                    </p>
                </div>
            ),
        },
        {
            title: "Статус Оплаты",
            key: "paymentStatus",
            sorter: true,
            align: "right",
            dataIndex: "paymentStatus",
            render: (statusId) => (
                <div className="text-right">
                    <p className="ml-2">
                        {
                            statusId === 2 ?
                                <Tag color="green">Вовремя оплаченный</Tag> :
                                statusId === 1 ?
                                    <Tag color="red">Вовремя неоплаченный</Tag> :
                                    '-'
                        }
                    </p>
                </div>
            ),
        },
        {
            title: "Последняя дата оплаты",
            key: "lastPaidDate",
            sorter: true,
            align: "right",
            dataIndex: "lastPaidDate",
            render: (item) => (
                <div className="text-right">
                    {item}
                </div>
            ),
        },
        {
            title: "Действия",
            key: "actions",
            dataIndex: "id",
            align: "right",
            render: (id, item) => {
                return (
                    <div className="text-right">
                        {item.statusId === 1 && (
                            <div className='d-flex justify-content-end flex-shrink-0'>
                                <Link
                                    to={"/admin/agreement/update/" + id}
                                    href='#'
                                    className='btn btn-icon btn-active-color-primary btn-sm me-1 shadow-sm'
                                >
                                    <CustomSvg path='/icons/duotune/art/art005.svg' className='svg-icon-3'/>
                                </Link>
                                <button
                                    onClick={() => deleteItem(id)}
                                    className='btn btn-icon shadow-sm btn-active-color-primary btn-sm'
                                >
                                    <CustomSvg
                                        path='/icons/duotune/general/gen027.svg'
                                        className='svg-icon-3'
                                    />
                                </button>
                            </div>
                        )}
                    </div>
                )
            },
        },
    ]

    return (<div
        className="br-5 card p-5 overflow-y-auto"
        style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}
    >
        <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1 ml-0 pl-0'
                      style={{position: "absolute", left: 20}}>Договора</span>
            </h3>
            <div
                className='card-toolbar'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Click to add a user'
            >
                <Link
                    to="/admin/agreement/create"
                    className='btn btn-sm btn-light-primary'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_invite_friends'
                >
                    <CustomSvg path='/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                    Добавить новый
                </Link>
            </div>
        </div>
        <div className="pb-4 pt-2">
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8} lg={3}>
                    <p className="p-0 m-0 text-black">Поиск</p>
                    <Search
                        value={value}
                        onChange={handleSearchInput}
                        placeholder="Поиск"
                        enterButton
                        allowClear
                    />
                </Col>
                <Col xs={24} sm={12} md={8} lg={3}>
                    <p className="p-0 m-0 text-black">Инвестиции</p>
                    <Select
                        labelInValue="test"
                        style={{width: '100%'}}
                        value={investment.id}
                        onChange={handleChangeInvestment}
                    >
                        {investments.map(inv => (
                            <Select.Option key={inv.id} value={inv.id}>
                                {inv.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col xs={24} sm={12} md={8} lg={3}>
                    <p className="p-0 m-0 text-black">Срок</p>
                    <Select
                        style={{width: '100%'}}
                        value={installment.id}
                        onChange={handleChangeInstallment}
                    >
                        {installments.map(inv => (
                            <Select.Option key={inv.id} value={inv.id}>
                                {inv.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col xs={24} sm={12} md={8} lg={3}>
                    <p className="p-0 m-0 text-black">Ставка</p>
                    <Select
                        style={{width: '100%'}}
                        value={condition.id}
                        onChange={handleChangeCondition}
                    >
                        {conditions.map(inv => (
                            <Select.Option key={inv.id} value={inv.id}>
                                {inv.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col xs={24} sm={12} md={8} lg={3}>
                    <p className="p-0 m-0 text-black">Статус</p>
                    <Select
                        style={{width: '100%'}}
                        value={status.id}
                        onChange={handleChangeStatus}
                    >
                        {statuses.map(inv => (
                            <Select.Option key={inv.id} value={inv.id}>
                                {inv.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col xs={24} sm={12} md={8} lg={3}>
                    <p className="p-0 m-0 text-black">Статус оплаты</p>
                    <Select
                        style={{width: '100%'}}
                        value={paymentStatus.id}
                        onChange={handleChangePaymentStatus}
                    >
                        {paymentStatuses.map(inv => (
                            <Select.Option key={inv.id} value={inv.id}>
                                {inv.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col xs={24} sm={12} md={8} lg={3}>
                    <button
                        onClick={weekOwnerAgents}
                        className="mr-0 box-shadow"
                        style={{
                            backgroundColor: "#fcfcfc",
                            height: 32,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid rgb(212, 211, 214)",
                            borderRadius: 5,
                            padding: "0 10px",
                            width: 150,
                            marginTop: 18
                        }}
                    >
                        <CustomSvg path='/icons/duotune/files/fil017.svg' className='svg-icon-3 mr-2'/>
                        Скачать Excel
                    </button>
                </Col>
            </Row>
        </div>
        <Table
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            data={get(items, "data", [])}
            totalElements={count}
            setSorter={setSorter}
            columns={columns}
        />
        {deleteModal.investment && (<AgreementDeleteModal
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            getItems={getItems}
        />)}
    </div>);
}

export default Agreement;
