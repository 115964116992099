import React, { useEffect, useState } from 'react';
import { PGET } from "../../../api/adminApi";
import { Card, Col, Row, Statistic } from 'antd';
import CountUp from "react-countup";
import { AiOutlineUser, AiOutlineShopping, AiOutlineFileText, AiOutlineCheckCircle, AiOutlineBarChart, AiOutlineFund, AiOutlinePieChart, AiOutlineWallet, AiOutlineBank, AiOutlineWarning } from "react-icons/ai";
import { get } from "lodash";

export default function Statistics() {
    const [items, setItems] = useState([])

    async function getItems() {
        const data = await PGET("/services/admin/api/dashboard-counts/", true, {});
        setItems(data.data);
    }

    const formatter = (value) => <CountUp end={value} separator="," />;

    useEffect(() => {
        getItems()
    }, [])

    return (
        <Row gutter={10}>
            <Col span={4} className="mt-4">
                <Card bordered={false}>
                    <Statistic
                        prefix={<AiOutlineFund />}
                        title="Инвестиции"
                        value={get(items, 'investmentCount', '0')}
                        formatter={formatter}
                    />
                </Card>
            </Col>
            <Col span={4} className="mt-4">
                <Card bordered={false}>
                    <Statistic
                        prefix={<AiOutlineUser />}
                        title="Клиенты"
                        value={get(items, 'clientCount', '0')}
                        formatter={formatter}
                    />
                </Card>
            </Col>
            <Col span={4} className="mt-4">
                <Card bordered={false}>
                    <Statistic
                        prefix={<AiOutlineShopping />}
                        title="Товары"
                        value={get(items, 'productCount', '0')}
                        precision={2}
                        formatter={formatter}
                    />
                </Card>
            </Col>
            <Col span={4} className="mt-4">
                <Card bordered={false}>
                    <Statistic
                        prefix={<AiOutlineFileText />}
                        title="Договора"
                        value={get(items, 'agreementCount', '0')}
                        precision={2}
                        formatter={formatter}
                    />
                </Card>
            </Col>
            <Col span={4} className="mt-4">
                <Card bordered={false}>
                    <Statistic
                        prefix={<AiOutlineCheckCircle />}
                        title="Активные договора"
                        value={get(items, 'activeAgreementCount', '0')}
                        precision={2}
                        formatter={formatter}
                    />
                </Card>
            </Col>
            <Col span={4} className="mt-4">
                <Card bordered={false}>
                    <Statistic
                        prefix={<AiOutlineBarChart />}
                        title="Закрытые договора"
                        value={get(items, 'closedAgreementCount', '0')}
                        precision={2}
                        formatter={formatter}
                    />
                </Card>
            </Col>
            <Col span={4} className="mt-4">
                <Card bordered={false}>
                    <Statistic
                        prefix={<AiOutlineBank />}
                        title="Общая инвестиция"
                        value={get(items, 'totalInvestmentSum', '0')}
                        precision={2}
                        formatter={formatter}
                    />
                </Card>
            </Col>
            <Col span={4} className="mt-4">
                <Card bordered={false}>
                    <Statistic
                        prefix={<AiOutlinePieChart />}
                        title="Общая прибыль"
                        value={get(items, 'totalProfitSum', '0')}
                        precision={2}
                        formatter={formatter}
                    />
                </Card>
            </Col>
            <Col span={4} className="mt-4">
                <Card bordered={false}>
                    <Statistic
                        prefix={<AiOutlinePieChart />}
                        title="Поступившая прибыль"
                        value={get(items, 'earnedProfitSum', '0')}
                        precision={2}
                        formatter={formatter}
                    />
                </Card>
            </Col>
            <Col span={4} className="mt-4">
                <Card bordered={false}>
                    <Statistic
                        prefix={<AiOutlineWallet />}
                        title="Снятый как дивиденд"
                        value={get(items, 'totalDividendSum', '0')}
                        precision={2}
                        formatter={formatter}
                    />
                </Card>
            </Col>
            <Col span={4} className="mt-4">
                <Card bordered={false}>
                    <Statistic
                        prefix={<AiOutlineWallet />}
                        title="Остаток"
                        value={get(items, 'investmentSum', '0')}
                        precision={2}
                        formatter={formatter}
                    />
                </Card>
            </Col>
            <Col span={4} className="mt-4">
                <Card bordered={false}>
                    <Statistic
                        prefix={<AiOutlineWarning />}
                        title="Задолженность"
                        value={get(items, 'remainSum', '0')}
                        precision={2}
                        formatter={formatter}
                    />
                </Card>
            </Col>
        </Row>
    )
}
