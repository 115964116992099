import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Form, Input, InputNumber, Modal, Radio, Select} from 'antd';
import {CiCirclePlus} from "react-icons/ci";
import {GET, POST} from "../../../api/adminApi";
import {useParams} from "react-router-dom";
import {get, map} from "lodash";
import {moneyFormatter} from "../../../utils/utilities";
import dayjs from "dayjs";

const FreePayment = ({getItems, serverData}) => {
    const {id} = useParams()
    const [open, setOpen] = useState()
    const [form] = Form.useForm()
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [date, setDate] = useState(get(serverData, 'currentLocalDate', dayjs))

    const showModal = () => {
        setOpen(true);
    };
    const handleSubmit = async (data) => {

        const obj = {
            ...data,
            agreementId: Number(id),
            paidDate: data.paidDate.format('YYYY-MM-DD')
        }

        setConfirmLoading(true);
        const res = await POST("/services/admin/api/agreement-installment-pay-amount", obj);
        if (res) {
            getItems()
            form.resetFields()
            setOpen(false);
            setConfirmLoading(false);
        }
    };
    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <>
            <Button onClick={showModal} type="primary" className="bg-green mr-2">Оплатить на произвольную сумму</Button>
            <Modal
                title={`Оплата по договору ${id}`}
                open={open}
                onOk={() => form.submit()}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Form form={form} onFinish={handleSubmit} layout="vertical" initialValues={{paidDate: date}}>
                    <Form.Item
                        label="Сумма"
                        name="paymentAmount"
                        rules={[
                            {required: true},
                            {
                                validator: async (_, value) => {
                                    if (value <= 100000000000) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(`Введите ниже ${moneyFormatter(100000000000)}`));
                                }
                            }
                        ]}
                    >
                        <InputNumber
                            style={{width: '100%'}}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        />
                    </Form.Item>
                    <Form.Item label="Дата" name="paidDate">
                        <DatePicker
                            value={date}
                            onChange={(e) => setDate(e)}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
export default FreePayment;