import React, {useEffect, useState} from "react";
import {DebounceInput} from "react-debounce-input";
import ReactPaginate from "react-paginate";
import {Edit} from "react-feather";
import {DELETE, PGET} from "../../../api/adminApi";
import {CustomSvg} from "../../../utils/CustomSvg";
import BlockUser from "./BlockUser";
import EditUser from "./EditUser";
import {Link} from "react-router-dom";

function Client() {
    const [items, setItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [value, setValue] = useState("");
    const [currentPage, setCurrentPage] = useState(
        +localStorage.getItem("currentPageClients") || 0
    ); //for numeration and saving currentPage after refresh
    const [modal, setModal] = useState({show: false, item: null});
    const [modalName, setModalName] = useState({show: false, item: null});
    const [changeStatusOptions, setChangeStatusOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    const handleSearchInput = (e) => {
        setValue(e.target.value);
        setCurrentPageToZero();
    };

    function setCurrentPageToZero() {
        setCurrentPage(0);
        localStorage.setItem("currentPageClients", 0);
    }

    function paginate(data) {
        setCurrentPage(data.selected);
        localStorage.setItem("currentPageClients", data.selected);
    }

    async function getItems() {
        const data = await PGET("/services/admin/api/client-pageList", true, {
            page: currentPage,
            size: 10,
            search: value,
        });
        setPageCount(Math.ceil(data.headers["x-total-count"] / 10));
        setItems(data.data);
    }
    async function deleteItem(id) {
        await DELETE("/services/admin/api/client/" + id);
        getItems();
    }
    useEffect(() => {
        getItems();
    }, [currentPage, value]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="br-5 card" style={{background: '#eef1ff', marginTop: "-0.9rem", height: '100vh'}}>
            <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Клиенты</span>
                </h3>
                <div
                    className='card-toolbar'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    data-bs-trigger='hover'
                    title='Click to add a user'
                >
                    <Link
                        to="/admin/client/create"
                        className='btn btn-sm btn-light-primary'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_invite_friends'
                    >
                        <CustomSvg path='/icons/duotune/arrows/arr075.svg' className='svg-icon-3'/>
                        Добавить новый
                    </Link>
                </div>
            </div>

            <div className="px-3 pb-4 pt-2">
                <div className="mb-4 d-flex justify-content-between">
                    <div className="position-relative">
                        <DebounceInput
                            minLength={3}
                            debounceTimeout={400}
                            value={value}
                            onChange={handleSearchInput}
                            type="text"
                            className="input-search search-icon br-5 "
                            size={24}
                            placeholder="Поиск"
                        />
                    </div>
                </div>
                <div style={{overflow: "auto", maxHeight: '75vh'}}>
                    <table className='table bg-white p-0 table-rounded table-striped border gy-4 gs-7'>
                        <thead className='position-sticky top-0 rounded'>
                        <tr className='fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200'>
                            <th className="text-center text-white backSvet" style={{
                                width: "30px",
                                backgroundColor: '#E7372C',
                                fontWeight: "700",
                                borderTopLeftRadius: '8px'
                            }}>
                                №
                            </th>
                            <th className="text-start text-white backSvet"
                                style={{fontWeight: "700"}}>ФИО
                            </th>
                            <th className="text-start text-white backSvet"
                                style={{fontWeight: "700"}}>Дата рождения
                            </th>
                            <th className="text-start text-white backSvet"
                                style={{fontWeight: "700"}}>Серия пасспорта
                            </th>
                            <th className="text-start text-white backSvet"
                                style={{fontWeight: "700"}}>Адрес
                            </th>
                            <th className="text-start text-white backSvet"
                                style={{fontWeight: "700"}}>Телефон
                            </th>
                            <th className="text-start text-white backSvet"
                                style={{fontWeight: "700"}}>Доп Телефон
                            </th>
                            <th className="backSvet" style={{
                                fontWeight: "700",
                                borderTopRightRadius: '8px'
                            }}></th>
                        </tr>
                        </thead>

                        <tbody style={{overflowX: "auto"}}>
                        {items?.map((item, idx) => {
                            return (
                                <tr key={item.id} className='borderBottom'>
                                    <td className="text-center">
                                        {currentPage === 0
                                            ? idx + 1
                                            : currentPage * 10 + idx + 1}
                                    </td>
                                    <td className="text-start">
                                        <div className="flex">
                                            <p className="mb-0 pb-0">{item.firstName}</p>
                                            <p className="mb-0 pb-0"> {item.lastName}</p>
                                        </div>
                                    </td>
                                    <td className="text-start">{item.birthday}</td>
                                    <td className="text-start">{item.passportNumber}</td>
                                    <td className="text-start">{item.address}</td>
                                    <td className="text-start">{item.phone}</td>
                                    <td className="text-start">{item.phoneAdditional}</td>
                                    <td>
                                        <div className='d-flex justify-content-end flex-shrink-0'>
                                            <Link
                                                to={"/admin/client/update/" + item.id}
                                                href='#'
                                                className='btn btn-icon btn-active-color-primary btn-sm me-1 shadow-sm'
                                            >
                                                <CustomSvg path='/icons/duotune/art/art005.svg' className='svg-icon-3'/>
                                            </Link>
                                            <button
                                                onClick={() => deleteItem(item.id)}
                                                className='btn btn-icon shadow-sm btn-active-color-primary btn-sm'
                                            >
                                                <CustomSvg
                                                    path='/icons/duotune/general/gen027.svg'
                                                    className='svg-icon-3'
                                                />
                                            </button>
                                        </div>

                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
                {pageCount > 1 && (
                    <ReactPaginate
                        forcePage={currentPage}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={1}
                        onPageChange={paginate}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        pageClassName={"page-item box-shadow"}
                    />
                )}
            </div>
            {modal.item && (
                <BlockUser
                    modal={modal}
                    setModal={setModal}
                    getItems={getItems}
                    statusOptions={changeStatusOptions}
                />
            )}
            {modalName.item && (
                <EditUser
                    modal={modalName}
                    setModal={setModalName}
                    getItems={getItems}
                    statusOptions={changeStatusOptions}
                />
            )}
        </div>
    );
}

export default Client;
