import {PGET} from "../../../api/adminApi";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Table from "../../ui/Table";
import {Col, Input, Row,} from "antd";
import {get} from "lodash";
import {moneyFormatter} from "../../../utils/utilities";
import {FaArrowDown, FaArrowUp} from "react-icons/fa";
import {CustomSvg} from "../../../utils/CustomSvg";
import {IoMdClose} from "react-icons/io";
import AgreementHistoryCancel from "./AgreementHistoryCancel";

const {Search} = Input;

export default function AgreementHistory() {
    const {id} = useParams()
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState()
    const [value, setValue] = useState("");

    const handleSearchInput = (e) => {
        setValue(e.target.value);
    };

    async function getItems() {
        const data = await PGET("/services/admin/api/agreement-operations", true, {
            agreementId: id,
            page: page - 1,
            size: perPage,
        });
        setTotalCount(Math.ceil(data.headers["x-total-count"]));
        setItems(data.data);
    }

    const columns = [
        {
            title: "№",
            key: "id",
            align: "center",
            dataIndex: "id",
            render: (id, item, index) => (
                <div className="text-right">
                    {page === 1 ? index + 1 : page * 10 + index + 1}
                </div>
            ),
        },
        {
            title: "Время",
            align: "right",
            key: "changedDate",
            dataIndex: "changedDate",
            render: (item) => (
                <div className="text-right">
                    {item}
                </div>
            ),
        },
        {
            title: "Сумма оплаты",
            key: "amount",
            align: "right",
            dataIndex: "amount",
            render: (amount) => {
                const iconStyle = {color: amount < 0 ? 'red' : 'green', marginRight: 5};
                return (
                    <div className="text-right">
                        {amount < 0 ? (
                            <FaArrowDown style={iconStyle}/>
                        ) : (
                            <FaArrowUp style={iconStyle}/>
                        )}
                        {moneyFormatter(amount)}
                    </div>
                );
            },
        },
        {
            title: "Описание",
            key: "description",
            align: "right",
            dataIndex: "description",
            render: (item) => (
                <div className="text-right">
                    {item}
                </div>
            ),
        },
        {
            title: "Действия",
            key: "actions",
            align: "center",
            dataIndex: "operationType",
            width: 20,
            render: (operationType, item) => {
                return (
                    operationType === 4 && <div className="text-center">
                        <div className='d-flex justify-center flex-shrink-0'>
                            <AgreementHistoryCancel id={item.id} />
                        </div>
                    </div>
                )
            }
        },
]

useEffect(() => {
    getItems()
}, [page, perPage, value]);
return (
    <div className="p-5">
        <div className="flex justify-between">
            <h3 className="my-5">История договора</h3>
        </div>
        {/*<div className="pb-4 pt-2 flex justify-between items-center">*/}
        {/*    <div className="w-100">*/}
        {/*<Row gutter={[16, 16]}>*/}
        {/*    <Col xs={24} sm={12} md={8} lg={4}>*/}
        {/*<p className="p-0 m-0 text-black">Поиск</p>*/}
        {/*<Search*/}
        {/*    value={value}*/}
        {/*    onChange={handleSearchInput}*/}
        {/*    placeholder="Поиск"*/}
        {/*    enterButton*/}
        {/*    allowClear*/}
        {/*/>*/}
        {/*</Col>*/}
        {/*</Row>*/}
        {/*</div>*/}
        {/*</div>*/}
        <Table
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            data={items}
            totalElements={totalCount}
            columns={columns}
        />
    </div>
)
}