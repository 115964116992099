import React, {useEffect, useState} from 'react';
import {Button, Form, InputNumber, Modal} from 'antd';
import {GET, POST} from "../../../api/adminApi";
import {useParams} from "react-router-dom";
import {moneyFormatter} from "../../../utils/utilities";

const UniquePayment = ({open, setOpen, getItems, currentId}) => {
    const {id} = useParams()
    const [form] = Form.useForm()
    const [confirmLoading, setConfirmLoading] = useState(false);
    const handleSubmit = async (data) => {

        const obj = {
            paymentAmount: 0,
            agreementId: Number(id),
            agreementInstallmentId: [currentId]
        }

        setConfirmLoading(true);
        const res = await POST("/services/admin/api/agreement-installment-pay-month-multiple", obj);
        if (res) {
            getItems()
            form.resetFields()
            setOpen(false);
            setConfirmLoading(false);
        }
    };
    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <>
            <Modal
                title={`Оплата по договору ${id}`}
                open={open}
                onOk={() => form.submit()}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    {/*<Form.Item*/}
                    {/*    label="Сумма"*/}
                    {/*    name="paymentAmount"*/}
                    {/*    rules={[*/}
                    {/*        {required: true},*/}
                    {/*        {*/}
                    {/*            validator: async (_, value) => {*/}
                    {/*                if (value <= 100000000000) {*/}
                    {/*                    return Promise.resolve();*/}
                    {/*                }*/}
                    {/*                return Promise.reject(new Error(`Введите ниже ${moneyFormatter(100000000000)}`));*/}
                    {/*            }*/}
                    {/*        }*/}
                    {/*    ]}*/}
                    {/*>*/}
                    {/*    <InputNumber*/}
                    {/*        style={{width: '100%'}}*/}
                    {/*        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}*/}
                    {/*        parser={value => value.replace(/\$\s?|(,*)/g, '')}*/}
                    {/*    />*/}
                    {/*</Form.Item>*/}
                </Form>
            </Modal>
        </>
    );
};
export default UniquePayment;